import { Component, Input, Output, EventEmitter, ViewChild, OnInit, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { Timesheet } from '../timesheet/timesheet.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivityService } from '../../services/acitvity.service';
import { CategoryService } from '../../services/category.service';
import { ActivityDTO } from '../activity/activity.model';
import { template } from '@babel/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { UserService } from '../../services/user.service';
import { UserDTO } from '../user/user.model';
import { ExportExcel } from '../../constant/ExcelExport';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { TimesheetService } from '../../services/timesheet.service';

@Component({
  selector: 'app-timesheet-list',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.css']
})
export class TimesheetListComponent implements OnInit {
  // [x: string]: any;
  totalItemsCount: number = 0;
  timesheets: Timesheet[] = [];
  // filters: any;
  pageSize = 10;
  currentPage = 0;
  isAdmin :boolean = false;
  userList:UserDTO[] = [];
  pageIndex = 0;
  length = 0;
  displayedColumns: string[] = ['workDate', 'categoryId', 'activityId', 'workDone', 'noOfHours', 'actions'];
  adminDisplayedColumns: string[] = ['workDate', 'categoryId', 'activityId', "userName", 'workDone', 'noOfHours'];
  dataSource = new MatTableDataSource<Timesheet>([]);
  @Output() isExcelBeingDownloaded = new EventEmitter<boolean>();
  today : Date = new Date()
  // @ViewChild(MatSort) sort!: MatSort;
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild('timesheetList')
  // timesheetList!: TimesheetListComponent;
  // timesheetUpdated: any;
  filterForm: FormGroup;
  userData:any
  filterActivityList:any[] = [];
  categoryList: any[] = [];
  activities: any[] = []
  @Input() isExcelExporting : any
  constructor(public datePipe: DatePipe, private userService : UserService, private exportData: ExportExcel, 
    private formBuilder: FormBuilder, private timesheetService: TimesheetService, private activityService: ActivityService, 
    private categoryService: CategoryService, private snackBar: MatSnackBar) {
    this.filterForm = this.formBuilder.group({
      workDate: [''],
      categoryId: ['-1'],
      activityId: ['-1'],
      userId:[''],
      startDate: [''],
      endDate: [''],
    });
  }

  ngOnInit(): void {
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
    this.getStorageData()
    this.filterForm.get("startDate")?.reset()
    this.filterForm.get("endDate")?.reset()
    this.getTimesheets();
    this.getAllCategory();
    if(this.isAdmin){
      this.getAllUsers();
    }
    this.filterForm.get("startDate")?.setValue(moment())
    this.filterForm.get("endDate")?.setValue(moment())
  }

  getAllUsers(): void {
    this.userService.getAllUsers(this.userData.id, 1, 500).subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject.responseData)) {
          this.userList = res.responseObject.responseData.map((user: UserDTO) => ({
            ...user,
          }));
          console.log('Updated userList array:', this.userList);
          // this.totalUsersCount = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          this.snackBar.open('Error fetching users: Invalid response format', 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching users:', error);
        this.snackBar.open(`Error fetching users: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  getCategoryName(categoryId: number){
    return this.categoryList.find( cat => cat.id == categoryId).name;
  }
  getAllCategory(): void {
    this.categoryService.getAllCategory().subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.categoryList = res.responseObject.map((res:any) => ({
            ...res,
          }));
          console.log('Updated categoryData array:', this.categoryList);
          // this.totalCategoryCount = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          // this.snackBar.open('Error fetching Category: Invalid response format', 'Close', {
          //   duration: 3000,
          //   verticalPosition: 'bottom',
          //   horizontalPosition: 'center'
          // });
        }
      },
      (error: any) => {
        console.error('Error fetching Category:', error);
        // this.snackBar.open(`Error fetching Category: ${error.message}`, 'Close', {
        //   duration: 3000,
        //   verticalPosition: 'bottom',
        //   horizontalPosition: 'center'
        // });
      }
    );
  }

  // ngAfterViewInit(): void {
  //   // this.dataSource.sort = this.sort;
  //   // this.dataSource.paginator = this.paginator;
  //   // this.paginator.length = this.totalItemsCount;
  // }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['filters'] && !changes['filters'].firstChange) {
  //     this.totalItemsCount = 0; // Reset the total items count when filters change
  //     this.currentPage = 0; // Reset the current page when filters change
  //     this.getTimesheets();
  //     this.applyFilters(); // Apply filters after getting new data
  //   }
  // }
  
  getStorageData(){
    let user:any = sessionStorage.getItem("userData");
    this.userData = JSON.parse(user);
    this.isAdmin = this.userData.roleId == 0;
  }

  getTimesheets(): void {
    let userId = this.userData.id;
    const filterData = this.filterForm.value;
    filterData.workDate = this.datePipe.transform(filterData.workDate, 'yyyy-MM-dd');
    filterData.startDate = this.datePipe.transform(filterData.startDate, 'yyyy-MM-dd');
    filterData.endDate = this.datePipe.transform(filterData.endDate, 'yyyy-MM-dd');
    if(this.isAdmin){
      userId = '-1';
    }
    this.timesheetService.getTimesheetList(filterData, userId ,this.currentPage,this.pageSize)
      .subscribe(response => {
        if(response.responseObject == undefined ){
          this.timesheets = [];
          this.totalItemsCount = 0;
          this.snackBar.open('No Data Found', 'Close', { duration: 3000 });
        }else{
          this.timesheets = response.responseObject.timesheetExportDTOList;
          this.totalItemsCount = response.responseObject.totalCounts;
        }
        this.dataSource.data = [...this.timesheets];
      }, error => {
        console.error('Error fetching timesheets:', error);
        this.snackBar.open('Failed to fetch timesheets.Try after Login ', 'Close', { duration: 3000 });
      });
  }

  exportTimesheet(): void {
    let userId = this.userData.id;
    const filterData = this.filterForm.value;
    filterData.workDate = this.datePipe.transform(filterData.workDate, 'yyyy-MM-dd');
    filterData.startDate = this.datePipe.transform(filterData.startDate, 'yyyy-MM-dd');
    filterData.endDate = this.datePipe.transform(filterData.endDate, 'yyyy-MM-dd');
    if(this.isAdmin){
      userId = '-1';
    }
    this.isExcelBeingDownloaded.emit(true);
    this.timesheetService.exportTimesheet(filterData, userId )
      .subscribe(response => {
        if(response.responseObject != undefined && response.responseObject != null && response.responseObject != ''){
          saveAs(this.exportData.base64ToBlob(response.responseObject),'timeSheet')
        }
        this.snackBar.open('Timesheet Excel export Initialted', 'Close', { duration: 3000 });
        this.isExcelBeingDownloaded.emit(false);
      }, error => {
        this.isExcelBeingDownloaded.emit(false);
        console.error('Error exporting timesheets:', error);
        this.snackBar.open('Failed to export timesheet excel.Try after Login ', 'Close', { duration: 3000 });
    });
    
  }

  onPageChange(event: any) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    console.log('Page changed:', event);
    this.getTimesheets();
    // this.pageChanged.emit({ pageIndex: event.pageIndex, pageSize: event.pageSize });
  }

  clearFilter(){
    this.filterForm.reset({ workDate: '',categoryId : '-1',activityId: '-1', userId: '',startDate : moment(), endDate: moment() });
    console.log(`reseted filter value : ${this.filterForm.value}`);
    this.getTimesheets();
  }
  // loadTimesheets(pageIndex: number) {
  //   this.timesheetService.getFilteredTimesheets({}, pageIndex, this.pageSize).subscribe((response: any) => {
  //     this.timesheets = response.content;
  //   });
  // }

  editTimesheet(timesheet: Timesheet): void {
    timesheet.editing = true;
    timesheet.activityId = -1;
    this.timesheets.map(sheet => {if(sheet.id != timesheet.id) sheet.editing == undefined})
    if(this.activities.length == 0){
      let type = this.categoryList.find(x => x.id == timesheet.categoryId).categoryTypeId;
      this.getActivityListByCategoryType(type)
    }else{
      this.activities = []
    }
  }

  cancelEdit(timesheet: Timesheet): void {
    timesheet.editing = false;
    this.getTimesheets();
  }

  saveTimesheet(timesheet: Timesheet): void {
    if(timesheet.activityId == -1 ){
      this.snackBar.open('activity is required.', 'Close', { duration: 3000 });
      return;
    }
    if (timesheet && timesheet.id) {
      // timesheet.startTime = this.formatTime(timesheet.startTime);
      // timesheet.endTime = this.formatTime(timesheet.endTime);
      this.timesheetService.updateTimesheet(timesheet.id, timesheet).subscribe(
        () => {
          this.snackBar.open('Timesheet updated successfully!', 'Close', { duration: 3000 });
          timesheet.editing = false;
          // this.timesheetUpdated.emit();
          this.getTimesheets();
        },
        (error: any) => this.handleError(error, 'Failed to update timesheet')
      );
    }
  }

  deleteTimesheet(id: number): void {
    if (confirm('Are you sure you want to delete this timesheet?')) {
      this.timesheetService.deleteTimesheet(id).subscribe(
        () => {
          this.snackBar.open('Timesheet deleted successfully!', 'Close', { duration: 3000 });
          this.getTimesheets();
          // this.timesheetUpdated.emit();
        },
        (error: any) => this.handleError(error, 'Failed to delete timesheet')
      );
    }
  }

  getActivityList(categoryId:number){
    let type = this.categoryList.find( cat => cat.id == categoryId).type;
    // return this.returnActivityListByCategoryType
  }

  // calculateHoursWorked(startTime: string, endTime: string): number {
  //   const startParts = startTime.split(':');
  //   const endParts = endTime.split(':');
  //   const startHours = parseInt(startParts[0], 10);
  //   const startMinutes = parseInt(startParts[1], 10);
  //   const endHours = parseInt(endParts[0], 10);
  //   const endMinutes = parseInt(endParts[1], 10);

  //   const startTotalMinutes = startHours * 60 + startMinutes;
  //   const endTotalMinutes = endHours * 60 + endMinutes;

  //   let totalMinutes = endTotalMinutes - startTotalMinutes;
  //   if (totalMinutes < 0) {
  //     totalMinutes += 24 * 60;
  //   }

  //   const hours = Math.floor(totalMinutes / 60);
  //   const minutes = totalMinutes % 60;

  //   return hours + minutes / 60;
  // }

  formatTime(time: string): string {
    const timeParts = time.split(':');
    if (timeParts.length === 2) {
      return `${timeParts[0].padStart(2, '0')}:${timeParts[1].padStart(2, '0')}:00`;
    } else {
      return time;
    }
  }

  // private applyFilters(): void {
  //   if (!this.filters ||!this.dataSource.data) return;

  //   const filteredData = this.dataSource.data.filter(timesheet => {
  //     let include = true;
  //     if (this.filters.workDoneFilter && !timesheet.workDone.toLowerCase().includes(this.filters.workDoneFilter.toLowerCase())) {
  //       include = false;
  //     }
  //     // if (this.filters.startDate && new Date(timesheet.workDate) < new Date(this.filters.startDate)) {
  //     //   include = false;
  //     // }
  //     // if (this.filters.endDate && new Date(timesheet.workDate) > new Date(this.filters.endDate)) {
  //     //   include = false;
  //     // }
  //     // if (this.filters.startTimeFilter && timesheet.startTime < this.formatTime(this.filters.startTimeFilter)) {
  //     //   include = false;
  //     // }
  //     // if (this.filters.endTimeFilter && timesheet.endTime > this.formatTime(this.filters.endTimeFilter)) {
  //     //   include = false;
  //     // }
  //     return include;
  //   });

  //   this.dataSource.data = [...filteredData];
  // }

  
  onCategorySelection(id:any) {
    let type = this.categoryList.find(category => category.id == id).categoryTypeId;
    console.log(` selecte value is ${type}`);
    this.getActivityListByCategoryType(type);
  }

  onfilterCategorySelection(id:any) {
    let type = this.categoryList.find(category => category.id == id).categoryTypeId;
    console.log(` selecte value is ${type}`);
    this.getFilterActivityList(type);
  }

  getFilterActivityList( value:any ): void {
    this.activityService.getActivityByCategoryType( value ).subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.filterActivityList = res.responseObject;
          console.log('Updated activityData array:', this.filterActivityList);
          if(this.filterActivityList.length == 1 && this.filterForm.get("categoryId")?.value != null){
            this.filterForm.get("activityId")?.setValue(this.filterActivityList[0].id)
          }else{
            this.filterForm.get("activityId")?.reset()
          }
        } else {
          console.error("Invalid response data:", res);
          this.snackBar.open('Error fetching Activity: Invalid response format', 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching Activity:', error);
        this.snackBar.open(`Error fetching Activity: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  getActivityListByCategoryType( value:any ): void {
    this.activityService.getActivityByCategoryType( value ).subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.activities = res.responseObject
          console.log('Updated activityData array:', this.activities);
          //this.activityList = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          // this.snackBar.open('Error fetching Activity: Invalid response format', 'Close', {
          //   duration: 3000,
          //   verticalPosition: 'bottom',
          //   horizontalPosition: 'center'
          // });
        }
      },
      (error: any) => {
        console.error('Error fetching Activity:', error);
        this.snackBar.open(`Error fetching Activity: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  onCategoryChange(event:any){
    let type = this.categoryList.find(x => x.id == event.value).type;
    this.getActivityListByCategoryType(type);
  }

  private handleError(error: any, message: string): void {
    console.error('Error:', error);
    this.snackBar.open(`${message}. Please try again.`, 'Close', { duration: 3000 });
  }

  onFilter(): void {
    this.getTimesheets()
  }

  getTotalCost(){
    return this.timesheets.map(t => t.noOfHours).reduce((acc:any, value:any) => acc + value, 0);
  }

}