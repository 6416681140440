import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { UserDTO } from '../user/user.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { Role } from '../user/role.model';
import { Department } from '../user/department.model';
import { Observable, tap, catchError, of } from 'rxjs';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'userName',
    'contact',
    'email',
    'department',
    'employeeCode',
    'joiningDate',
    'birthDate',
    'role',
    'employeeType',
    'actions'
  ];
  userData: UserDTO[] = [];
  totalUsersCount!: number;
  pageSize: number = 10;
  currentPage: number = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  roles: Role[] = [];
  departments: Department[] = [];
  employeeTypes: { id: number, type: string }[] = [
    // { id: 0, type: 'ADMIN' },
    { id: 1, type: 'EMPLOYEE' },
    { id: 2, type: 'MERIL CONSULTANT' },
    { id: 3, type: 'PRIME CONSULTANT' },
  ];

  usersJsonData:any

  constructor(
    private userService: UserService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.getStorageData()
    // Fetch roles, departments, and users only on component initialization
    this.getRoles().subscribe(() => {
      this.getDepartments().subscribe(() => {
        this.getAllUsers(this.currentPage, this.pageSize);
      });
    });
  }

  getStorageData(){
    let user:any = sessionStorage.getItem("userData")
    this.usersJsonData = JSON.parse(user)
  }

  getRoles(): Observable<Role[]> {
    return this.userService.getRoles().pipe(
      tap((roles: Role[]) => {
        this.roles = roles; // assign the roles array to this.roles
      }),
      catchError((error: any) => {
        console.error('Error fetching roles:', error);
        this.snackBar.open(`Error fetching roles: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
        return of([]); // return an empty array if there's an error
      })
    );
  }

  getDepartments(): Observable<Department[]> {
    return this.userService.getDepartments().pipe(
      tap((departments: Department[]) => {
        this.departments = departments; // assign the departments array to this.departments
      }),
      catchError((error: any) => {
        console.error('Error fetching departments:', error);
        this.snackBar.open(`Error fetching departments: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
        return of([]); // return an empty array if there's an error
      })
    );
  }

  getAllUsers( pageNo: number, pageSize: number ): void {
    this.userService.getAllUsers(this.usersJsonData.id, pageNo, pageSize).subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject.responseData)) {
          this.userData = res.responseObject.responseData.map((user: UserDTO) => ({
            ...user,
            role: this.getRoleName(user.roleId),
            department: this.getDepartmentName(user.departmentId),
            employeeType: this.getEmployeeTypeName(user.employeeTypeId)
          }));
          console.log('Updated userData array:', this.userData);
          this.totalUsersCount = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          this.snackBar.open('Error fetching users: Invalid response format', 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching users:', error);
        this.snackBar.open(`Error fetching users: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  getRoleName(roleId: number): string | undefined {
    if (!this.roles) {
      return undefined;
    }
    const role = this.roles.find(r => r.id === roleId);
    return role ? role.role : undefined;
  }

  getDepartmentName(departmentId: number): string | undefined {
    if (!this.departments) {
      return undefined;
    }
    const department = this.departments.find(d => d.id === departmentId);
    return department ? department.department : undefined;
  }

  getEmployeeTypeName(employeeTypeId: number): string | undefined {
    if (!this.employeeTypes) {
      return undefined;
    }
    const employeeType = this.employeeTypes.find(et => et.id === employeeTypeId);
    return employeeType? employeeType.type : undefined;
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.getAllUsers(this.currentPage, this.pageSize);
  }

  editUser(element: any): void {
    element.editing = true;
  }

  saveUser(element: any): void {
    console.log('Roles array:', this.roles);
    console.log('Departments array:', this.departments);
    console.log('Element role:', element.role);
    console.log('Element department:', element.department);
    console.log('Element employeeType:', element.employeeType);
  
    const userData = { ...element };
    console.log("element ::::: ",element);
    
    // const selectedRole = this.roles.find(role => role.role === element.role);
    // if (selectedRole) {
    //   userData.roleId = selectedRole.id;
    // } else {
    //   console.log('Selected role not found:', element.role);
    // }
  
    // const selectedDepartment = this.departments.find(department => department.department === element.department);
    // if (selectedDepartment) {
    //   userData.departmentId = selectedDepartment.id;
    // } else {
    //   console.log('Selected department not found:', element.department);
    // }
  
    // const selectedEmployeeType = this.employeeTypes.find(et => et.type === element.employeeType);
    // console.log("selectedEmployeeType ::::: ",selectedEmployeeType);
    // console.log("element.employeeTypeId ::::: ",element.employeeTypeId);
    
    // if (selectedEmployeeType) {
    //   userData.employeeTypeId = selectedEmployeeType.id;
    // } else {
    //   console.log('Selected employee type not found:', element.employeeType);
    // }
    userData.roleId = element.roleId ? Number(element.roleId) : null;
    userData.departmentId = element.departmentId ? Number(element.departmentId) : null;
    userData.employeeTypeId = element.employeeTypeId ? Number(element.employeeTypeId) : null;
    delete userData.role;
    delete userData.department;
    delete userData.employeeType;
  
    console.log('Updated user data:', userData);
  
    this.userService.update(userData).subscribe(
      (res: any) => {
        element.editing = false;
        console.log('User updated successfully:', res);
  
        // Update the role, department, and employeeType properties of the element object
        const roleName = this.getRoleName(userData.roleId);
        const departmentName = this.getDepartmentName(userData.departmentId);
        const employeeTypeName = this.getEmployeeTypeName(userData.employeeTypeId);
        console.log('Role name:', roleName);
        console.log('Department name:', departmentName);
        console.log('Employee type name:', employeeTypeName);
        console.log('User role ID:', userData.roleId);
        console.log('User department ID:', userData.departmentId);
        console.log('User employee type ID:', userData.employeeTypeId);
  
        if (roleName !== undefined) {
          element.role = roleName;
        }
  
        if (departmentName !== undefined) {
          element.department = departmentName;
        }
  
        if (employeeTypeName !== undefined) {
          element.employeeType = employeeTypeName;
        }
  
        // Update the userData array
        const index = this.userData.findIndex(user => user.id === element.id);
        if (index !== -1) {
          this.userData[index].roleId = userData.roleId;
          element.role = this.roles?.find(r => r.id === userData.roleId)?.role ?? '';
          this.userData[index].departmentId = userData.departmentId;
          element.department = this.departments?.find(d => d.id === userData.departmentId)?.department ?? '';
          this.userData[index].employeeTypeId = userData.employeeTypeId;
          element.employeeType = this.employeeTypes?.find(et => et.id === userData.employeeTypeId)?.type ?? '';
          console.log('Updated userData array:', this.userData);
        } else {
          console.log('User not found in userData array:', element.id);
        }
        this.snackBar.open(res.message, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      },
      (error: any) => {
        console.error('Error updating user:', error);
        this.snackBar.open(error.message, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  cancelEdit(element: any): void {
    element.editing = false;
    this.getAllUsers(this.currentPage,this.pageSize);
  }

  deleteUser(element: any): void {
    if (confirm(`Are you sure you want to delete user ${element.firstName} ${element.lastName}?`)) {
      this.userService.deleteById(element.id).subscribe(
        () => {
          // Pass the current page size to the getAllUsers method
          this.getAllUsers(this.currentPage, this.pageSize);
        },
        (error: any) => {
          console.error('Error deleting user:', error);
        }
      );
    }
  }

  resetUser(element:any){
    if (confirm(`Are you sure you want to reset user ${element.firstName} ${element.lastName}?`)) {
      this.userService.resetUserPassword(element.id).subscribe(
        (res:any) => {
          // Pass the current page size to the getAllUsers method
          // this.getAllUsers(this.currentPage, this.pageSize);
          this.snackBar.open(res.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        },
        (error: any) => {
          console.error('Error deleting user:', error);
        }
      );
    }
  }
}