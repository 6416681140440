import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Timesheet } from './timesheet.model';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe, formatDate } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {  MomentDateAdapter } from '@angular/material-moment-adapter';
import { TimesheetListComponent } from '../timesheet-list/timesheet-list.component';
import { CategoryService } from '../../services/category.service';
import { ActivityService } from '../../services/acitvity.service';
import moment from 'moment';
import { TimesheetService } from '../../services/timesheet.service';

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-timesheet',
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.css'],
  providers: [
    DatePipe,
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class TimesheetComponent implements OnInit {
  timesheetForm: FormGroup;
  filterForm: FormGroup;
  timesheets: Timesheet[] = [];
  submittedSuccessfully = false;
  currentPage = 0;
  pageSize = 10;

  @ViewChild(TimesheetListComponent) timeSheetListCompo : any;
  totalItemsCount: any;
  userData:any
  isAdmin: boolean=false;
  categoryList: any[] = [];
  activityList: any[] = [];
  isExcelExporting: boolean = false;
  exportButtonText: string = 'Export Timesheet';
  today : Date = new Date()

  constructor(
    private categoryService: CategoryService,
    private activityService: ActivityService,
    private formBuilder: FormBuilder,
    private timesheetService: TimesheetService,
    private snackBar: MatSnackBar,
    public datePipe: DatePipe,
    private dateAdapter: DateAdapter<any>
  ) {
    this.dateAdapter.setLocale('en-GB');

    this.timesheetForm = this.formBuilder.group({
      workDate: [moment(), Validators.required],
      categoryId: ['', Validators.required],
      activityId: ['', Validators.required],
      noOfHours: ['', Validators.required],
      workDone: [''],
      // startTime: [''], 
      // endTime: [''], 
      userId:['']
    });

    this.filterForm = this.formBuilder.group({
      workDoneFilter: [''],
      startDate: [''],
      endDate: [''],
      startTimeFilter: [''],
      endTimeFilter: [''],
    });
  }

  ngOnInit(): void {
    this.getStorageData();
    this.getAllCategory();
  }

  getAllCategory(): void {
    this.categoryService.getAllCategory().subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.categoryList = res.responseObject.map((res:any) => ({
            ...res,
          }));
          console.log('Updated categoryData array:', this.categoryList);
          // this.totalCategoryCount = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          // this.snackBar.open('Error fetching Category: Invalid response format', 'Close', {
          //   duration: 3000,
          //   verticalPosition: 'bottom',
          //   horizontalPosition: 'center'
          // });
        }
      },
      (error: any) => {
        console.error('Error fetching Category:', error);
        // this.snackBar.open(`Error fetching Category: ${error.message}`, 'Close', {
        //   duration: 3000,
        //   verticalPosition: 'bottom',
        //   horizontalPosition: 'center'
        // });
      }
    );
  }

  exportTimesheet(){
    this.timeSheetListCompo.exportTimesheet()
  }

  getStorageData(){
    let user:any = sessionStorage.getItem("userData")
    this.userData = JSON.parse(user)
    if(this.userData){
      this.timesheetForm.get("userId")?.setValue(this.userData.id)
    }
    this.isAdmin = this.userData.roleId == 0;
  }

  // ngAfterViewInit(): void {
  //   setTimeout(() => {
  //     if (this.timesheetList) {
  //       this.timesheetList.pageChanged.subscribe((event: any) => {
  //         this.loadTimesheets(this.filterForm.value, event.pageIndex, event.pageSize);
  //       });
  //     } else {
  //       console.log('timesheetList is not defined');
  //     }
  //   }, 0);
  // }

  onSubmit(): void {
    if(this.isAdmin){
      this.snackBar.open('Admin cannot create timesheet.', 'Close', { duration: 3000 });
      return;
    }
    if(this.timesheetForm.get("userId")?.value == null ){
      this.timesheetForm.get("userId")?.setValue(this.userData.id)
    }
    if (this.timesheetForm.valid) {
      const timesheetData: Timesheet = { ...this.timesheetForm.value };
      const workDateValue: Date | null = this.timesheetForm.get('workDate')?.value;
      if (workDateValue) {
        timesheetData.workDate = new Date(formatDate(workDateValue, 'yyyy-MM-dd', 'en') ) ;
      }

      // const startTimeValue: string | null = this.timesheetForm.get('startTime')?.value;
      // if (startTimeValue) {
      //   timesheetData.startTime = this.formatTime(startTimeValue);
      // }

      // const endTimeValue: string | null = this.timesheetForm.get('endTime')?.value;
      // if (endTimeValue) {
      //   timesheetData.endTime = this.formatTime(endTimeValue);
      // }

      console.log('Submitting timesheet:', timesheetData);

      this.timesheetService.createTimesheet(timesheetData).subscribe(
        () => {
          this.snackBar.open('Timesheet submitted successfully!', 'Close', { duration: 3000 });
          this.submittedSuccessfully = true;
          this.timesheetForm.reset();
          this.clearErrorStates();
          this.timeSheetListCompo.getTimesheets();
          // this.loadTimesheets();
        },
        (error: HttpErrorResponse) => this.handleError(error, 'Failed to submit timesheet')
      );
    } else {
      this.snackBar.open('Please fill all required fields.', 'Close', { duration: 3000 });
    }
  }

  onCategorySelection(id:any) {
    let type = this.categoryList.find(category => category.id == id).categoryTypeId;
    console.log(` selecte value is ${type}`);
    this.getActivityListByCategoryType(type);
  }

  getActivityListByCategoryType( value:any ): void {
    this.activityService.getActivityByCategoryType( value ).subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.activityList = res.responseObject
          console.log('Updated activityData array:', this.activityList);
          if(this.activityList.length == 1 && this.timesheetForm.get("categoryId")?.value != null){
            this.timesheetForm.get("activityId")?.setValue(this.activityList[0].id)
          }else{
            this.timesheetForm.get("activityId")?.reset()
          }
          //this.activityList = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          // this.snackBar.open('Error fetching Activity: Invalid response format', 'Close', {
          //   duration: 3000,
          //   verticalPosition: 'bottom',
          //   horizontalPosition: 'center'
          // });
        }
      },
      (error: any) => {
        console.error('Error fetching Activity:', error);
        this.snackBar.open(`Error fetching Activity: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  onFilter(): void {
    const filterData = this.filterForm.value;
    console.log('Filter data:', filterData);
    filterData.startDate = this.datePipe.transform(filterData.startDate, 'yyyy-MM-dd');
    filterData.endDate = this.datePipe.transform(filterData.endDate, 'yyyy-MM-dd');
    // this.loadTimesheets(filterData);
  }

  // loadTimesheets(filters: any = {}, pageIndex: number = 0, pageSize: number = 10): void {
  //   console.log('Loading timesheets with filters:', filters);
  //   console.log('PageIndex:', pageIndex);
  //   console.log('PageSize:', pageSize);
  //   this.timesheetService.getFilteredTimesheets(filters,null, pageIndex, pageSize).subscribe(
  //     (response: any) => {
  //       console.log('Timesheet response:', response);
  //       this.timesheets = response.content;
  //       if (this.timesheetList) {
  //         this.timesheetList.timesheets = response.content;
  //         this.timesheetList.totalItemsCount = response.totalElements;
  //       }
  //     },
  //     (error: HttpErrorResponse) => this.handleError(error, 'Failed to load timesheets')
  //   );
  // }

  // onTimesheetUpdated(): void {
  //   this.loadTimesheets(this.filterForm.value);
  // }

  private handleError(error: HttpErrorResponse, message: string): void {
    console.error('Error:', error);
    this.snackBar.open(`${message}. Please try again.`, 'Close', { duration: 3000 });
  }

  private clearErrorStates(): void {
    const formControls = ['workDate','categoryId','activityId','workDone'];
    formControls.forEach(controlName => {
      const control = this.timesheetForm.get(controlName);
      if (control) {
        control.setErrors(null);
      }
    });
  }

  private formatTime(time: string): string {
    const timeParts = time.split(':');
    if (timeParts.length === 2) {
      return `${timeParts[0].padStart(2, '0')}:${timeParts[1].padStart(2, '0')}:00`;
    } else {
      return time;
    }
  }

  excelExportButtonStatus(value:any){
    this.isExcelExporting = value;
    if(value){
      this.exportButtonText = 'Exporting... ';
    }else{
      this.exportButtonText = 'Export Timesheet';
    }
  }

}