@if (isAdmin) {
  <!-- <mat-snack-bar-container></mat-snack-bar-container> -->
  <div class="container">
    <div class="mat-elevation-z8">
      <form [formGroup]="categoryFormGroup" (ngSubmit)="onSaveCategory()" class="user-form">
        <h2>Category</h2>
        
        <div class="form-row">
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Category Name</mat-label>
              <input matInput formControlName="name" required>
              <mat-error *ngIf="categoryFormGroup.get('name')?.invalid && categoryFormGroup.get('name')?.touched">
                Category Name is required
              </mat-error>
            </mat-form-field>
          </div>
          
          
          <div class="field">
            <mat-form-field appearance="outline">
              <mat-label>Category Type</mat-label>
              <mat-select formControlName="categoryTypeId" required>
                <mat-option *ngFor="let type of typeList" [value]="type.id">{{ type.type }}</mat-option>
              </mat-select>
              <mat-error *ngIf="categoryFormGroup.get('categoryTypeId')?.invalid && categoryFormGroup.get('categoryTypeId')?.touched">
                Category Type is required
              </mat-error>
            </mat-form-field>
          </div>
          <button mat-raised-button color="primary" type="submit" >Submit</button>
        </div> <!-- end of form-row -->
        
        <div *ngIf="isSubmitting" class="loading">
          <mat-spinner diameter="30"></mat-spinner>
          <span>Submitting...</span>
        </div>
      </form>
      <app-category-list></app-category-list>
    </div>
  </div>
} @else {
  <p>You are not authorized.</p>
}