@if (isAdmin) {
  
<!-- <mat-snack-bar-container></mat-snack-bar-container> -->
<div class="container">
  <div class="mat-elevation-z8">
    <form [formGroup]="userForm" (ngSubmit)="onSave()" class="user-form">
      <h2>User Details</h2>

      <div class="form-row">
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>User Name</mat-label>
            <input matInput formControlName="userName" required>
            <mat-error *ngIf="userForm.get('userName')?.invalid && userForm.get('userName')?.touched">
              User Name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>First Name</mat-label>
            <input matInput formControlName="firstName" required>
            <mat-error *ngIf="userForm.get('firstName')?.invalid && userForm.get('firstName')?.touched">
              First Name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" required>
            <mat-error *ngIf="userForm.get('lastName')?.invalid && userForm.get('lastName')?.touched">
              Last Name is required
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email">
            <!-- <mat-error *ngIf="userForm.get('email')?.invalid && userForm.get('email')?.touched">
              Valid Email is required
            </mat-error> -->
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Contact</mat-label>
            <input matInput formControlName="contact">
            <!-- <mat-error *ngIf="userForm.get('contact')?.invalid && userForm.get('contact')?.touched">
              Contact is required
            </mat-error> -->
          </mat-form-field>
        </div>


      <div class="field">
        <mat-form-field appearance="outline">
          <mat-label>Department</mat-label>
          <mat-select formControlName="departmentId">
            <mat-option *ngFor="let department of departments" [value]="department.department">{{ department.department }}</mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="userForm.get('department')?.invalid && userForm.get('department')?.touched">
            Department is required
          </mat-error> -->
        </mat-form-field>
      </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Employee Code</mat-label>
            <input matInput formControlName="employeeCode">
            <!-- <mat-error *ngIf="userForm.get('employeeCode')?.invalid && userForm.get('employeeCode')?.touched">
              Employee Code is required
            </mat-error> -->
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Joining Date</mat-label>
            <input matInput [matDatepicker]="joiningDatePicker" formControlName="joiningDate">
            <mat-datepicker-toggle matSuffix [for]="joiningDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #joiningDatePicker></mat-datepicker>
            <!-- <mat-error *ngIf="userForm.get('joiningDate')?.invalid && userForm.get('joiningDate')?.touched">
              Joining Date is required
            </mat-error> -->
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Birth Date</mat-label>
            <input matInput [matDatepicker]="birthDatePicker" formControlName="birthDate">
            <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #birthDatePicker></mat-datepicker>
            <!-- <mat-error *ngIf="userForm.get('birthDate')?.invalid && userForm.get('birthDate')?.touched">
              Birth Date is required
            </mat-error> -->
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Role</mat-label>
            <mat-select formControlName="role" required>
              <mat-option *ngFor="let role of roles" [value]="role.role">{{ role.role }}</mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.get('role')?.invalid && userForm.get('role')?.touched">
              Role is required
            </mat-error>
          </mat-form-field>
        </div>
        
        
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>Employee Type</mat-label>
            <mat-select formControlName="employeeType" required>
              <mat-option *ngFor="let employeeType of employeeTypes" [value]="employeeType.employeeType">{{ employeeType.employeeType }}</mat-option>
            </mat-select>
            <mat-error *ngIf="userForm.get('employeeType')?.invalid && userForm.get('employeeType')?.touched">
              Employee Type is required
            </mat-error>
          </mat-form-field>
        </div>
        <button mat-raised-button color="primary" type="submit" [disabled]="isSubmitting || userForm.invalid">Submit</button>
      </div> <!-- end of form-row -->

      <div *ngIf="isSubmitting" class="loading">
        <mat-spinner diameter="30"></mat-spinner>
        <span>Submitting...</span>
      </div>

    </form>
    <!-- User List (Moved to UserListComponent) -->
    <app-user-list></app-user-list>
  </div>
</div>

} @else {
  <p>You are not authorized.</p>
}
