import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  
  showFiller = false;
  @Input() iconClick: any
  roleId: string = '';
  userData:any
  userName:any
  isAdmin:boolean=false;

  constructor(private route:Router){}

  ngOnInit(): void {
    this.getStorageData()
  }
  
  getStorageData(){
    this.roleId = localStorage.getItem('roleId')?? '';
    let user:any = sessionStorage.getItem("userData")
    this.userData = JSON.parse(user)
    if(this.userData){
      this.userName = this.userData.userName
    }
    this.isAdmin = this.userData.roleId == 0;
  }

  onLogOut(){
    this.route.navigate([''])
    sessionStorage.clear()
    localStorage.clear()
  }
}