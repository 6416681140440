<!-- <mat-snack-bar-container></mat-snack-bar-container> -->
<div class="mat-elevatz8ion-">
</div>
<!-- Timesheet Entry Form -->
<button mat-raised-button color="primary" (click)="exportTimesheet()" type="button" class="excel-export-button" [disabled]="isExcelExporting"> @if(isExcelExporting === true){<span class="spinner-size"><mat-spinner [diameter]="20"></mat-spinner></span>}{{exportButtonText}}</button>
<form [formGroup]="timesheetForm" (ngSubmit)="onSubmit()" class="timesheet-form" *ngIf="!isAdmin">
  <div class="head">
    <h2 class="timesheet-title">Timesheet Entry</h2>
    <!-- <button mat-raised-button color="primary" (click)="exportTimesheet()" type="button" class="excel-export-button" [disabled]="isExcelExporting"> @if(isExcelExporting === true){<span class="spinner-size"><mat-spinner [diameter]="20"></mat-spinner></span>}{{exportButtonText}}</button> -->
  </div>
  <div class="form-row">

    <div class="field">
      <mat-form-field appearance="outline" class="work-date-field" [class.has-error]="timesheetForm.get('workDate')?.invalid && timesheetForm.get('workDate')?.touched && !submittedSuccessfully">
        <mat-label>Work Date</mat-label>
        <input matInput [matDatepicker]="workDatePicker" [max]="today" formControlName="workDate" required>
        <mat-datepicker-toggle matSuffix [for]="workDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #workDatePicker></mat-datepicker>
        <mat-error *ngIf="timesheetForm.get('workDate')?.invalid && timesheetForm.get('workDate')?.touched && !submittedSuccessfully">
          Work Date is required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Category Selection -->
    <div class="field">
      <mat-form-field appearance="outline">
        <mat-label>Category</mat-label>
        <mat-select formControlName="categoryId" (selectionChange)="onCategorySelection($event.value)" required>
          <mat-option *ngFor="let category of categoryList" [value]="category.id">{{ category.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="timesheetForm.get('categoryId')?.invalid && timesheetForm.get('categoryId')?.touched">
          Category is required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- Activity Selection -->
    <div class="field">
      <mat-form-field appearance="outline">
        <mat-label>Activity</mat-label>
        <mat-select formControlName="activityId" required>
          <mat-option *ngFor="let activity of activityList" [value]="activity.id">{{ activity.name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="timesheetForm.get('activityId')?.invalid && timesheetForm.get('activityId')?.touched">
          Activity is required
        </mat-error>
      </mat-form-field>
    </div>

    <!-- No Of Hours Field -->
    <div class="field">
      <mat-form-field appearance="outline">
          <mat-label>Number Of Hours</mat-label>
          <input type="number" step="0.1" min="0.1" matInput formControlName="noOfHours" required>
          <mat-error *ngIf="timesheetForm.get('noOfHours')?.invalid && timesheetForm.get('noOfHours')?.touched">
            Number Of Hours is required
          </mat-error>
      </mat-form-field>
    </div>

    <!-- Description or  Work Done Field -->
    <div class="field">
      <mat-form-field appearance="outline" class="work-done-field">
        <mat-label>Description</mat-label>
        <input matInput type="text" formControlName="workDone">
        <!-- <mat-error *ngIf="timesheetForm.get('workDone')?.invalid && timesheetForm.get('workDone')?.touched && !submittedSuccessfully">
          Description is required
        </mat-error> -->
      </mat-form-field>
    </div>
    
    <!-- Start time Field -->
    <!-- <mat-form-field appearance="outline" class="start-time-field" [class.has-error]="timesheetForm.get('startTime')?.invalid && timesheetForm.get('startTime')?.touched && !submittedSuccessfully">
      <mat-label>Start Time</mat-label>
      <input matInput type="time" formControlName="startTime" required>
      <mat-error *ngIf="timesheetForm.get('startTime')?.invalid && timesheetForm.get('startTime')?.touched && !submittedSuccessfully">
        Start Time is required
      </mat-error>
    </mat-form-field> -->

    <!-- End time Field -->
    <!-- <mat-form-field appearance="outline" class="end-time-field" [class.has-error]="timesheetForm.get('endTime')?.invalid && timesheetForm.get('endTime')?.touched && !submittedSuccessfully">
      <mat-label>End Time</mat-label>
      <input matInput type="time" formControlName="endTime" required>
      <mat-error *ngIf="timesheetForm.get('endTime')?.invalid && timesheetForm.get('endTime')?.touched && !submittedSuccessfully">
        End Time is required
      </mat-error>
    </mat-form-field> -->

    <!-- Work Done Field -->
    <!-- <mat-form-field appearance="outline" class="work-done-field" [class.has-error]="timesheetForm.get('workDone')?.invalid && timesheetForm.get('workDone')?.touched && !submittedSuccessfully">
      <mat-label>Work Done</mat-label>
      <input matInput type="text" formControlName="workDone" required>
      <mat-error *ngIf="timesheetForm.get('workDone')?.invalid && timesheetForm.get('workDone')?.touched && !submittedSuccessfully">
        Work Done is required
      </mat-error>
    </mat-form-field> -->

    <button mat-raised-button color="primary" type="submit">Submit</button>
  </div>
</form>


<app-timesheet-list (isExcelBeingDownloaded)="excelExportButtonStatus($event)" ></app-timesheet-list>

