// userService.ts

import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CategoryDTO } from '../components/category/category.model'; // Add department model
import { baseURL, getHeaders, VERSION } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  constructor(private http: HttpClient) { }

  create(categoryDTO: CategoryDTO): Observable<CategoryDTO> {
    return this.http.post<CategoryDTO>(`${baseURL}categories/${VERSION}`, categoryDTO, getHeaders());
  }

  getAllCategory(): Observable<CategoryDTO[]> {
    const options = {
      headers: getHeaders().headers
    };
    return this.http.get<CategoryDTO[]>(`${baseURL}categories/${VERSION}/list`, options)
      .pipe(
        tap(response => console.log('getAllCategory response:', response)),
        catchError(error => {
          console.error('Error fetching All Category:', error);
          return throwError(error);
        })
      );
  }

  getCategoryType(): Observable<any[]> {
    const options = {
      headers: getHeaders().headers
    };
    return this.http.get<any[]>(`${baseURL}categories/${VERSION}/category/type`, options)
      .pipe(
        tap(response => console.log('getCategoryType response:', response)),
        catchError(error => {
          console.error('Error fetching CategoryType:', error);
          return throwError(error);
        })
      );
  }

  getCategoryById(id: number): Observable<CategoryDTO> {
    return this.http.get<CategoryDTO>(`${baseURL}categories/${VERSION}?id=${id}`, getHeaders());
  }

  update(CategoryDTO: CategoryDTO): Observable<CategoryDTO> {
    console.log('Updating Category:', CategoryDTO);
    return this.http.put<CategoryDTO>(`${baseURL}categories/${VERSION}`, CategoryDTO, getHeaders()).pipe(
      tap(response => console.log('Update response:', response)),
      catchError(error => {
        console.error('Error updating Category:', error);
        return throwError(error);
      })
    );
  }

  deleteById(id: number): Observable<CategoryDTO> {
    return this.http.delete<CategoryDTO>(`${baseURL}categories/${VERSION}?id=${id}`, getHeaders());
  }

  
}