import { Observable, throwError, BehaviorSubject } from "rxjs";
import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError } from "rxjs/operators";
import { Timesheet } from "../components/timesheet/timesheet.model";
import { baseURL, VERSION } from "../constant/constant";

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {

  // private apiUrl = 'http://localhost:8090/timesheets/v1';
  private pageSize = 10;
  private authToken = localStorage.getItem('authToken');

  private currentPageSubject = new BehaviorSubject<number>(0);
  private pageSizeSubject = new BehaviorSubject<number>(this.pageSize);

  currentPage$ = this.currentPageSubject.asObservable();
  pageSize$ = this.pageSizeSubject.asObservable();

  constructor(private http: HttpClient) {}

  createTimesheet(timesheet: Timesheet): Observable<Timesheet> {
    return this.http.post<Timesheet>(`${baseURL}timesheets/${VERSION}`, timesheet, {
      headers: {
        Authorization: `Bearer ${this.authToken}`
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  getFilteredTimesheets(filters: any, userId:any, page: number = 1, size: number = 10): Observable<any> {
    let params = new HttpParams()
      .set('userId', userId.toString())
      .set('page', page.toString())
      .set('size', size.toString());

    if (filters) {
      if(filters.userId){
        params = params.set('userId', filters.userId);
      }
      if (filters.workDoneFilter) {
        params = params.set('workDone', filters.workDoneFilter);
      }
      if (filters.startDate) {
        const startDate = new Date(filters.startDate);
        params = params.set('startDate', startDate.toISOString().split('T')[0]);
      }
      if (filters.endDate) {
        const endDate = new Date(filters.endDate);
        params = params.set('endDate', endDate.toISOString().split('T')[0]);
      }
      if (filters.startTimeFilter) {
        params = params.set('startTime', filters.startTimeFilter);
      }
      if (filters.endTimeFilter) {
        params = params.set('endTime', filters.endTimeFilter);
      }
    }

    return this.http.get<any>(`${baseURL}timesheets/${VERSION}/filter`, {
      params,
      headers: {
        Authorization: `Bearer ${this.authToken}`
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  getTimesheetList(filters: any, userId:any, page: number = 0, size: number = 10): Observable<any> {
    let params = new HttpParams()
    .set('userId', userId.toString())
    .set('pageNo', page.toString())
    .set('pageSize', size.toString());

    if (filters) {
      if(filters.userId != ''){
        params = params.set('userId', filters.userId);
      }
      // if (filters.workDoneFilter) {
      //   params = params.set('workDone', filters.workDoneFilter);
      // }
      if(filters.workDate != '' && filters.workDate != null ){
        params = params.set('workDate', filters.workDate);
      }
      if(filters.activityId){
        params = params.set('activityId', filters.activityId);
      }
      if(filters.categoryId){
        params = params.set('categoryId', filters.categoryId);
      }
      if (filters.startDate != '' && filters.startDate != null) {
        const startDate = new Date(filters.startDate);
        params = params.set('startDate', filters.startDate);
      }
      if (filters.endDate != '' && filters.endDate != null) {
        const endDate = new Date(filters.endDate);
        params = params.set('endDate', filters.endDate);
      }
      // if (filters.startTimeFilter) {
      //   params = params.set('startTime', filters.startTimeFilter);
      // }
      // if (filters.endTimeFilter) {
      //   params = params.set('endTime', filters.endTimeFilter);
      // }
    }

    // userId, startDate, endDate, categoryId, activityId

    return this.http.get<any>(`${baseURL}timesheets/${VERSION}/list`, {
      params,
      headers: {
        Authorization: `Bearer ${this.authToken}`
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  exportTimesheet(filters: any, userId:any): Observable<any> {
    let params = new HttpParams()
    .set('userId', userId.toString());

    if (filters) {
      if(filters.userId != ''){
        params = params.set('userId', filters.userId);
      }
      // if (filters.workDoneFilter) {
      //   params = params.set('workDone', filters.workDoneFilter);
      // }
      if(filters.workDate != '' && filters.workDate != null ){
        params = params.set('workDate', filters.workDate);
      }
      if(filters.activityId){
        params = params.set('activityId', filters.activityId);
      }
      if(filters.categoryId){
        params = params.set('categoryId', filters.categoryId);
      }
      if (filters.startDate != '' && filters.startDate != null) {
        const startDate = new Date(filters.startDate);
        params = params.set('startDate', filters.startDate);
      }
      if (filters.endDate != '' && filters.endDate != null) {
        const endDate = new Date(filters.endDate);
        params = params.set('endDate', filters.endDate);
      }
      // if (filters.startTimeFilter) {
      //   params = params.set('startTime', filters.startTimeFilter);
      // }
      // if (filters.endTimeFilter) {
      //   params = params.set('endTime', filters.endTimeFilter);
      // }
    }

    // userId, startDate, endDate, categoryId, activityId

    return this.http.get<any>(`${baseURL}timesheets/${VERSION}/export`, {
      params,
      headers: {
        Authorization: `Bearer ${this.authToken}`
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  updateTimesheet(id: number, timesheet: Timesheet): Observable<Timesheet> {
    return this.http.put<Timesheet>(`${baseURL}timesheets/${VERSION}/${id}`, timesheet, {
      headers: {
        Authorization: `Bearer ${this.authToken}`
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  deleteTimesheet(id: number): Observable<void> {
    return this.http.delete<void>(`${baseURL}timesheets/${VERSION}/${id}`, {
      headers: {
        Authorization: `Bearer ${this.authToken}`
      }
    }).pipe(
      catchError(this.handleError)
    );
  }

  setCurrentPage(page: number) {
    this.currentPageSubject.next(page);
  }

  setPageSize(size: number) {
    this.pageSizeSubject.next(size);
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An error occurred';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      errorMessage = `Error: ${error.status}, Message: ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
