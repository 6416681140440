<div class="mat-elevation-z8">

  
  <!-- Filter Timesheet Form -->
  <form [formGroup]="filterForm" (ngSubmit)="onFilter()" class="timesheet-form">
    <h5>Filter Timesheet</h5>
    <div class="form-row">
      <!-- <div class="field">
        <mat-form-field appearance="outline" class="work-date-field">
          <mat-label>Work Date</mat-label>
          <input matInput [matDatepicker]="workDatePicker" formControlName="workDate">
          <mat-datepicker-toggle matSuffix [for]="workDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #workDatePicker></mat-datepicker>
          <mat-error *ngIf="filterForm.get('workDate')?.invalid && filterForm.get('workDate')?.touched && !submittedSuccessfully">
            Work Date is required
          </mat-error>
        </mat-form-field>
      </div> -->

      <!-- Category Selection -->
      <div class="field">
        <mat-form-field appearance="outline">
          <mat-label>Category</mat-label>
          <mat-select formControlName="categoryId" (selectionChange)="onfilterCategorySelection($event.value)">
            <mat-option *ngFor="let category of categoryList" [value]="category.id">{{ category.name }}</mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="filterForm.get('categoryId')?.invalid && filterForm.get('categoryId')?.touched">
            Category is required
          </mat-error> -->
        </mat-form-field>
      </div>

      <!-- Activity Selection -->
      <div class="field">
        <mat-form-field appearance="outline">
          <mat-label>Activity</mat-label>
          <mat-select formControlName="activityId">
            <mat-option *ngFor="let activity of filterActivityList" [value]="activity.id">{{ activity.name }}</mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="filterForm.get('activityId')?.invalid && filterForm.get('activityId')?.touched">
            Activity is required
          </mat-error> -->
        </mat-form-field>
      </div>

      <!-- User Selection -->
      @if(isAdmin){
        <div class="field">
          <mat-form-field appearance="outline">
            <mat-label>User</mat-label>
            <mat-select formControlName="userId" >
              <mat-option *ngFor="let user of userList" [value]="user.id">{{ user.userName }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      }

      <div class="field">
        <mat-form-field appearance="outline" class="start-date-field">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <!-- <mat-error *ngIf="filterForm.get('workDate')?.invalid && filterForm.get('workDate')?.touched && !submittedSuccessfully">
            Work Date is required
          </mat-error> -->
        </mat-form-field>
      </div>

      <div class="field">
        <mat-form-field appearance="outline" class="end-date-field">
          <mat-label>End Date</mat-label>
          <input matInput [matDatepicker]="endDatePicker" formControlName="endDate">
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatePicker></mat-datepicker>
          <!-- <mat-error *ngIf="filterForm.get('workDate')?.invalid && filterForm.get('workDate')?.touched && !submittedSuccessfully">
            Work Date is required
          </mat-error> -->
        </mat-form-field>
      </div>

      <button mat-raised-button color="primary" type="submit">Filter</button>
      <button mat-raised-button color="primary" (click)="clearFilter()" type="button">Clear</button>
    </div>
  </form>

  <mat-paginator 
  [length]="totalItemsCount" 
  [pageSize]="pageSize" 
  [pageSizeOptions]="[5, 10, 25, 100]" 
  showFirstLastButtons 
  (page)="onPageChange($event)">
  </mat-paginator>

  <table mat-table [dataSource]="timesheets" class="my-table">
    <!-- ID Column -->
    <!-- <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef>ID</th>
      <td mat-cell *matCellDef="let timesheet">{{ timesheet.id }}</td>
    </ng-container> -->

    <!-- Work Date Column -->
    <ng-container matColumnDef="workDate">
      <th mat-header-cell *matHeaderCellDef>Work Date</th>
      <td mat-cell *matCellDef="let timesheet">
        <span *ngIf="!timesheet.editing">{{ timesheet.workDate | date:'dd-MM-yyyy' }}</span>
        <mat-form-field appearance="outline" *ngIf="timesheet.editing">
          <input matInput [matDatepicker]="workDatePicker" [max]="today" [(ngModel)]="timesheet.workDate">
          <mat-datepicker-toggle matSuffix [for]="workDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #workDatePicker></mat-datepicker>
        </mat-form-field>
        <!-- <input *ngIf="timesheet.editing" type="date" [(ngModel)]="timesheet.workDate" matInput class="editable-input"> -->
      </td>
      @if(isAdmin){
        <td mat-footer-cell *matFooterCellDef colspan="5" style="text-align: right;"> 
          <b>Total No. of hours</b>
        </td>
      }@else{
        <td mat-footer-cell *matFooterCellDef colspan="4" style="text-align: right;">
          <b>Total No. of hours</b>
        </td>
      }
    </ng-container>

    <!-- Category Column -->
    <ng-container matColumnDef="categoryId">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let timesheet ">
        <span *ngIf="!timesheet.editing">{{ timesheet.study }}</span>
        <mat-form-field appearance="outline" *ngIf="timesheet.editing">
          <mat-select [(ngModel)]="timesheet.categoryId" (selectionChange)="onCategorySelection($event.value)">
            <mat-option *ngFor="let category of categoryList" [value]="category.id">{{ category.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <select *ngIf="timesheet.editing" [(ngModel)]="timesheet.categoryId" (change)="onCategoryChange($event.target)" matInput class="editable-input">
          <option *ngFor="let category of categoryList" [value]="category.id">{{ category.name }}</option>
        </select> -->
      </td>
      <td mat-footer-cell *matFooterCellDef hidden></td>
    </ng-container>

    <!-- Activity Column -->
    <ng-container matColumnDef="activityId">
      <th mat-header-cell *matHeaderCellDef> Activity </th>
      <td mat-cell *matCellDef="let timesheet">
        <span *ngIf="!timesheet.editing">{{ timesheet.activity }}</span>
        <mat-form-field appearance="outline" *ngIf="timesheet.editing">
          <mat-select [(ngModel)]="timesheet.activityId">
            <mat-option [value]="-1" selected>--Select Activity--</mat-option>
            <mat-option *ngFor="let activity of activities" [value]="activity.id">{{ activity.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <select *ngIf="timesheet.editing" [(ngModel)]="timesheet.activityId" matInput class="editable-input">
          <option [value]="-1" selected >Select Activity</option>
          <option *ngFor="let activity of activities" [value]="activity.id">{{ activity.name }}</option>
        </select> -->
      </td>
      <td mat-footer-cell *matFooterCellDef hidden></td>
    </ng-container>

    @if(isAdmin){
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef> User Name </th>
        <td mat-cell *matCellDef="let timesheet">
          <span>{{ timesheet.userName }}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef hidden></td>
      </ng-container>
    }

    <!-- Start Time Column -->
    <!-- <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef>Start Time</th>
      <td mat-cell *matCellDef="let timesheet">
        <span *ngIf="!timesheet.editing">{{ timesheet.startTime }}</span>
        <input *ngIf="timesheet.editing" type="time" [(ngModel)]="timesheet.startTime" matInput class="editable-input">
      </td>
    </ng-container> -->

    <!-- End Time Column -->
    <!-- <ng-container matColumnDef="endTime">
      <th mat-header-cell *matHeaderCellDef>End Time</th>
      <td mat-cell *matCellDef="let timesheet">
        <span *ngIf="!timesheet.editing">{{ timesheet.endTime }}</span>
        <input *ngIf="timesheet.editing" type="time" [(ngModel)]="timesheet.endTime" matInput class="editable-input">
      </td>
    </ng-container> -->

    <!-- Work Done Column -->
    <ng-container matColumnDef="workDone">
      <th mat-header-cell *matHeaderCellDef>Work Description</th>
      <td mat-cell *matCellDef="let timesheet">
        <span *ngIf="!timesheet.editing">{{ timesheet.workDone }}</span>
        <mat-form-field appearance="outline" *ngIf="timesheet.editing">
          <input matInput type="text" [(ngModel)]="timesheet.workDone">
        </mat-form-field>
        <!-- <input *ngIf="timesheet.editing" type="text" [(ngModel)]="timesheet.workDone" matInput class="editable-input"> -->
      </td>
      <td mat-footer-cell *matFooterCellDef="let timesheet" hidden></td>
    </ng-container>

    <!-- Hours Worked Column -->
     <ng-container matColumnDef="noOfHours">
      <th mat-header-cell *matHeaderCellDef>Number Of Hours</th>
      <td mat-cell *matCellDef="let timesheet">
        <span *ngIf="!timesheet.editing">{{ timesheet.noOfHours }} hrs</span>
        <mat-form-field appearance="outline" *ngIf="timesheet.editing">
          <input type="number" step="0.1" min="0.1" matInput [(ngModel)]="timesheet.noOfHours">
        </mat-form-field>
        <!-- <input *ngIf="timesheet.editing" type="number" step="0.1" [(ngModel)]="timesheet.noOfHours" matInput class="editable-input"> -->
      </td>
      <td mat-footer-cell *matFooterCellDef="let timesheet">
        <b>{{getTotalCost()}} hrs</b>
      </td>
    </ng-container>

    @if(isAdmin){
      <tr mat-header-row *matHeaderRowDef="adminDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let timesheet; columns: adminDisplayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="adminDisplayedColumns"></tr>
    }@else{
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let timesheet">
          <button mat-raised-button color="primary" *ngIf="!timesheet.editing" (click)="editTimesheet(timesheet)">Edit</button> &nbsp;
          <button mat-raised-button color="warn" *ngIf="!timesheet.editing" (click)="deleteTimesheet(timesheet.id)">Delete</button> &nbsp;
          <button mat-raised-button color="primary" *ngIf="timesheet.editing" (click)="saveTimesheet(timesheet)">Save</button> &nbsp;
          <button mat-raised-button *ngIf="timesheet.editing" (click)="cancelEdit(timesheet)">Cancel</button>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    }
  </table>
</div>
