import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivityDTO } from './activity.model';
import { ActivityService } from '../../services/acitvity.service';
import { ActivityListComponent } from '../activity-list/activity-list.component';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrl: './activity.component.css',
})
export class ActivityComponent implements OnInit {
  @ViewChild(ActivityListComponent) activityListCompo:any;
  
displayedColumns: string[] = [
  'categoryName',
  'categoryType',
];
activityData: ActivityDTO[] = [];
isSubmitting:boolean = false;
submitSuccess: boolean = false;
submitError: string = '';

activityFormGroup: FormGroup;
  categoryJsonData: any;
  typeList: any;
  userData: any;
  isAdmin: boolean = false;

constructor(
  private activityService: ActivityService,
  private fb: FormBuilder,
  private snackBar: MatSnackBar
) {
  this.activityFormGroup = this.fb.group({
    name: ['', Validators.required],
    categoryTypeId: ['', Validators.required],
  });
}

  ngOnInit(): void {
      this.getActivityCategoryType();
      this.getStorageData();
  }

  getStorageData(){
    let user:any = sessionStorage.getItem("userData")
    this.userData = JSON.parse(user)
    this.isAdmin = this.userData.roleId == 0;
  }


  getActivityCategoryType(){
    this.activityService.getActivityCategoryType().subscribe(
      (res: any) => {
        if (res && res.responseObject) {
          if (Array.isArray(res.responseObject)) {
            this.typeList = res.responseObject.map((type: any) => ({
             ...type,
            }));
          } else {
            console.error("Invalid response data:", res);
          }
          // this.snackBar.open('Types fetched successfully!', 'Close', {
          //   duration: 3000,
          //   verticalPosition: 'bottom',
          //   horizontalPosition: 'center'
          // });
        } else {
          console.error("Invalid types response format:", res);
          this.snackBar.open(res.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching type:', error);
        this.snackBar.open(error.message, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  onSaveCategory(){
    console.log(this.activityFormGroup)
    if (this.activityFormGroup.valid) {
      const formValue = this.activityFormGroup.value;
      const activityData = {
       ...formValue
      };
  
      this.activityService.create(activityData).subscribe(
        (res: any) => {
          this.isSubmitting = false;
          this.submitSuccess = true;
          this.activityData.push(res.responseObject); // Add new user data to the array
          this.activityListCompo.getAllActivity(1,10); // Refresh the table
          this.snackBar.open(res.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
  
          // Reset form and clear error states
          this.activityFormGroup.reset();
          Object.keys(this.activityFormGroup.controls).forEach(key => {
            this.activityFormGroup.get(key)?.setErrors(null);
          });
  
          // Mark form as pristine and untouched
          this.activityFormGroup.markAsPristine();
          this.activityFormGroup.markAsUntouched();
        },
        (error: any) => {
          this.isSubmitting = false;
          this.submitSuccess = false;
          this.submitError = 'Error submitting form';
          this.snackBar.open(error.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
          console.error('Error submitting form: ', error);
        }
      );
    } else {
      this.submitSuccess = false;
      this.submitError = 'Form is invalid. Please check all fields.';
      this.snackBar.open('Form is invalid. Please check all fields.', 'Close', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
  
      // Mark all fields as touched to show errors
      this.activityFormGroup.markAllAsTouched();
    }
  };

}

