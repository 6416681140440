<!-- <app-header [iconClick]="drawer" class="header-design"></app-header> -->
<!-- <mat-drawer-container class="example-container">
    <mat-drawer #drawer mode="over">I'm a drawer</mat-drawer>
    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>
</mat-drawer-container> -->
<app-header></app-header>
<div class="example-container">
    <router-outlet></router-outlet>
</div>
