<mat-toolbar>
  <!-- (click)="drawer.toggle()" -->
  <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="iconClick.toggle()">
    <mat-icon>menu</mat-icon>
  </button> -->
  <span>Tracker App</span>
  &nbsp;<button mat-button *ngIf="roleId === 'ADMIN'" [routerLink]="['/home/dashboard']">Dashboard</button>
  &nbsp;<span [hidden]="!isAdmin"><button mat-button *ngIf="roleId === 'ADMIN'" [routerLink]="['/home/user']">Users</button></span>
  &nbsp;<button mat-button [routerLink]="['/home/timesheet']">Timesheet</button>
  &nbsp;<span [hidden]="!isAdmin"><button mat-button [routerLink]="['/home/category']">Category</button></span>
  &nbsp;<span [hidden]="!isAdmin"><button mat-button [routerLink]="['/home/activity']">Activity</button></span>
  <span class="example-spacer"></span>
  <button mat-button [matMenuTriggerFor]="menu"><mat-icon>people</mat-icon> {{userName}}</button>
  <mat-menu #menu="matMenu">
    <section [hidden]="isAdmin">
      <button mat-menu-item [hidden]="true" [routerLink]="['/home/changePassword']">
        <mat-icon>password</mat-icon>
        <span>Change Password</span>
      </button>
    </section>
    <button mat-menu-item (click)="onLogOut()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
  <!-- <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button> -->
</mat-toolbar>