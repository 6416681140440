import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { CategoryDTO } from './category.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CategoryListComponent } from '../category-list/category-list.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrl: './category.component.css'
})
export class CategoryComponent implements OnInit {
@ViewChild(CategoryListComponent) categoryListCompo:any;
displayedColumns: string[] = [
  'categoryName',
  'categoryType',
];
categoryData: CategoryDTO[] = [];
isSubmitting:boolean = false;
submitSuccess: boolean = false;
submitError: string = '';

categoryFormGroup: FormGroup;
  typeList: any;
  userData: any;
  isAdmin: boolean=false;

  constructor(
    private categoryService: CategoryService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ){
    this.categoryFormGroup = this.fb.group({
      name: ['', Validators.required],
      categoryTypeId: ['', Validators.required],
  });}

  ngOnInit(): void {
    this.getCategoryType();
    this.getStorageData();
  }

  getStorageData(){
    let user:any = sessionStorage.getItem("userData")
    this.userData = JSON.parse(user)
    this.isAdmin = this.userData.roleId == 0;
  }

  getCategoryType(){
    this.categoryService.getCategoryType().subscribe(
      (res: any) => {
        if (res && res.responseObject) {
          if (Array.isArray(res.responseObject)) {
            this.typeList = res.responseObject.map((type: any) => ({
             ...type,
            }));
          } else {
            console.error("Invalid response data:", res);
          }
        } else {
          console.error("Invalid types response format:", res);
          this.snackBar.open(res.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching type:', error);
        this.snackBar.open(error.message, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }
  onSaveCategory(){
    console.log(this.categoryFormGroup)
    if (this.categoryFormGroup.valid) {
      const formValue = this.categoryFormGroup.value;
      const categoryData = {
       ...formValue
      };
  
      this.categoryService.create(categoryData).subscribe(
        (res: any) => {
          this.isSubmitting = false;
          this.submitSuccess = true;
          this.categoryData.push(res.responseObject); // Add new user data to the array
          this.categoryListCompo.getAllCategory(1,10); // Refresh the table
          this.snackBar.open(res.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
  
          // Reset form and clear error states
          this.categoryFormGroup.reset();
          Object.keys(this.categoryFormGroup.controls).forEach(key => {
            this.categoryFormGroup.get(key)?.setErrors(null);
          });
  
          // Mark form as pristine and untouched
          this.categoryFormGroup.markAsPristine();
          this.categoryFormGroup.markAsUntouched();
        },
        (error: any) => {
          this.isSubmitting = false;
          this.submitSuccess = false;
          this.submitError = 'Error submitting form';
          this.snackBar.open(error.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
          console.error('Error submitting form: ', error);
        }
      );
    } else {
      this.submitSuccess = false;
      this.submitError = 'Form is invalid. Please check all fields.';
      this.snackBar.open('Form is invalid. Please check all fields.', 'Close', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
  
      // Mark all fields as touched to show errors
      this.categoryFormGroup.markAllAsTouched();
    }
  };

}

