import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent implements OnInit{
  @Input() iconClick:any
  ngOnInit(): void {
    // throw new Error('Method not implemented.');
  }

}
