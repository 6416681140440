import { Component, OnInit,ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { ActivityDTO } from '../activity/activity.model';
import { ActivityService } from '../../services/acitvity.service';
@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrl: './activity-list.component.css'
})
export class ActivityListComponent {
  
  displayedColumns: string[] = [
    'name',
    'type',
    'actions',
  ];
  activityData: ActivityDTO[] = [];
  totalActivityCount!: number;
  pageSize: number = 10;
  currentPage: number = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  typeList: any;

  constructor(
    private snackBar: MatSnackBar,private activityService: ActivityService
  ) {}

  ngOnInit(): void {
    this.getAllActivity();
    this.getActivityCategoryType()
  }

  getCategoryName(departmentId: number): string | undefined {
    if (!this.typeList) {
      return undefined;
    }
    const type = this.typeList.find((t: { id: number; }) => t.id === departmentId);
    return type ? type.type : undefined;
  }

  getActivityCategoryType(): void {
    this.activityService.getActivityCategoryType().subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.typeList = res.responseObject.map((activity: any) => ({
            ...activity,
          }));
          console.log('Updated typeList array:', this.activityData);
          this.totalActivityCount = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          this.snackBar.open('Error fetching Activity typeList', 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching Activity:', error);
        this.snackBar.open(`Error fetching Activity: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  getAllActivity(): void {
    this.activityService.getAllActivity().subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.activityData = res.responseObject.map((activity: ActivityDTO) => ({
            ...activity,
          }));
          console.log('Updated activityData array:', this.activityData);
          this.totalActivityCount = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          this.snackBar.open('Error fetching Activity: Invalid response format', 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching Activity:', error);
        this.snackBar.open(`Error fetching Activity: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  onPageChange(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex + 1;
    this.getAllActivity();
  }

  editActivity(element: any): void {
    element.editing = true;
  }

  saveActivity(element: any): void {

    const acitvityData = { ...element };
  
    console.log('Updated user data:', acitvityData);
  
    this.activityService.update(acitvityData).subscribe(
      (res: any) => {
        element.editing = false;
        this.snackBar.open('Activity updated successfully!', 'Close', { duration: 3000 });
        console.log('Activity updated successfully:', res);
        this.getAllActivity();
  
      },
      (error: any) => {
        console.error('Error updating user:', error);
      }
    );
  }

  cancelEdit(element: any): void {
    element.editing = false;
    this.getAllActivity();
  }

  deleteActivity(element: any): void {
    if (confirm(`Are you sure you want to delete activity "${element.name}" ?`)) {
      this.activityService.deleteById(element.id).subscribe(
        () => {
          // Pass the current page size to the getAllUsers method
          this.snackBar.open('Activity Deleted successfully!', 'Close', { duration: 3000 });
          this.getAllActivity();
        },
        (error: any) => {
          console.error('Error deleting user:', error);
        }
      );
    }
  }
}