// userService.ts

import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivityDTO } from '../components/activity/activity.model'; // Add department model
import { baseURL, getHeaders, VERSION } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient) { }

  create(activityDTO: ActivityDTO): Observable<ActivityDTO> {
    return this.http.post<ActivityDTO>(`${baseURL}activities/${VERSION}`, activityDTO, getHeaders());
  }

  getAllActivity(): Observable<ActivityDTO[]> {
    const options = {
      headers: getHeaders().headers
    };
    return this.http.get<ActivityDTO[]>(`${baseURL}activities/${VERSION}/list`, options)
      .pipe(
        tap(response => console.log('getAllActivities response:', response)),
        catchError(error => {
          console.error('Error fetching All Activity:', error);
          return throwError(error);
        })
      );
  }

  getActivityCategoryType(): Observable<any[]> {
    const options = {
      headers: getHeaders().headers
    };
    return this.http.get<any[]>(`${baseURL}categories/${VERSION}/category/type`, options)
      .pipe(
        tap(response => console.log('getActivityCategoryType response:', response)),
        catchError(error => {
          console.error('Error fetching Category Type:', error);
          return throwError(error);
        })
      );
  }

  getCategoryById(id: number): Observable<ActivityDTO> {
    return this.http.get<ActivityDTO>(`${baseURL}activities/${VERSION}?id=${id}`, getHeaders());
  }

  getActivityByCategoryType(type: number): Observable<ActivityDTO[]> {
    return this.http.get<ActivityDTO[]>(`${baseURL}activities/${VERSION}/type?categoryTypeId=${type}`, getHeaders());
  }

  update(ActivityDTO: ActivityDTO): Observable<ActivityDTO> {
    console.log('Updating Category:', ActivityDTO);
    return this.http.put<ActivityDTO>(`${baseURL}activities/${VERSION}`, ActivityDTO, getHeaders()).pipe(
      tap(response => console.log('Update response:', response)),
      catchError(error => {
        console.error('Error updating Category:', error);
        return throwError(error);
      })
    );
  }

  deleteById(id: number): Observable<ActivityDTO> {
    return this.http.delete<ActivityDTO>(`${baseURL}activities/${VERSION}?id=${id}`, getHeaders());
  }

}