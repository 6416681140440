import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PageEvent } from '@angular/material/paginator';
import { Observable, tap, catchError, of } from 'rxjs';
import { CategoryDTO } from '../category/category.model';
import { CategoryService } from '../../services/category.service';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrl: './category-list.component.css'
})
export class CategoryListComponent implements OnInit {
  displayedColumns: string[] = [
    'name',
    'type',
    'actions',
  ];
  categoryData: CategoryDTO[] = [];
  totalCategoryCount!: number;
  pageSize: number = 10;
  currentPage: number = 1;
  pageSizeOptions: number[] = [5, 10, 25, 100];
  typeList: any;

  constructor(
    private snackBar: MatSnackBar,private categoryService: CategoryService
  ) {}

  ngOnInit(): void {
    this.getAllCategory();
    this.getCategoryType();
  }
  
  getCategoryName(departmentId: number): string | undefined {
    if (!this.typeList) {
      return undefined;
    }
    const type = this.typeList.find((t: { id: number; }) => t.id === departmentId);
    return type ? type.type : undefined;
  }

  getCategoryType(){
    this.categoryService.getCategoryType().subscribe(
      (res: any) => {
        if (res && res.responseObject) {
          if (Array.isArray(res.responseObject)) {
            this.typeList = res.responseObject.map((type: any) => ({
             ...type,
            }));
          } else {
            console.error("Invalid response data:", res);
          }
          // this.snackBar.open('Types fetched successfully!', 'Close', {
          //   duration: 3000,
          //   verticalPosition: 'bottom',
          //   horizontalPosition: 'center'
          // });
        } else {
          console.error("Invalid types response format:", res);
          this.snackBar.open('Error fetching types: Invalid response format', 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching type:', error);
        this.snackBar.open(`Error fetching type: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  getAllCategory( ): void {
    this.categoryService.getAllCategory().subscribe(
      (res: any) => {
        if (Array.isArray(res.responseObject)) {
          this.categoryData = res.responseObject.map((category: CategoryDTO) => ({
            ...category,
          }));
          console.log('Updated categoryData array:', this.categoryData);
          this.totalCategoryCount = res.responseObject.totalCount; // Set the totalUsersCount property
        } else {
          console.error("Invalid response data:", res);
          this.snackBar.open('Error fetching Category: Invalid response format', 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching Category:', error);
        this.snackBar.open(`Error fetching Category: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  // onPageChange(event: PageEvent): void {
  //   this.pageSize = event.pageSize;
  //   this.currentPage = event.pageIndex + 1;
  //   this.getAllCategory(this.currentPage, this.pageSize);
  // }

  editCategory(element: any): void {
    element.editing = true;
  }

  saveCategory(element: any): void {
  
    const categoryData = { ...element };
    console.log('Updated user data:', categoryData);

      this.categoryService.update(categoryData).subscribe((res: any) => {
        element.editing = false;  
        console.log('Category updated successfully:', res);
        this.snackBar.open('Category updated successfully!', 'Close', { duration: 3000 });
        this.getAllCategory();
      },
      (error: any) => {
        console.error('Error updating user:', error);
      }
    );
  }

  cancelEdit(element: any): void {
    element.editing = false;
    this.getAllCategory();
  }

  deleteCategory(element: any): void {
    if (confirm(`Are you sure you want to delete category "${element.name}" ?`)) {
      this.categoryService.deleteById(element.id).subscribe(
        () => {
          // Pass the current page size to the getAllUsers method
          this.snackBar.open('Category Deleted successfully!', 'Close', { duration: 3000 });
          this.getAllCategory();
        },
        (error: any) => {
          console.error('Error deleting user:', error);
        }
      );
    }
  }
}