<div class="main-div">
    <mat-card class="card-design">
        <mat-card-header>
            <mat-card-title>
                <h5>Change Password</h5>
            </mat-card-title>
        </mat-card-header>
        <mat-divider></mat-divider>
        <form *ngIf="changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="setChangePassword()">
            <mat-card-content>
                <div class="row">
                    <mat-form-field appearance="outline" class="mat-content">
                        <mat-label>Old Password</mat-label>
                        <input matInput placeholder="Enter Old Password" type="password" formControlName="oldPassword">
                    </mat-form-field>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="mat-content">
                            <mat-label>New Password</mat-label>
                            <input matInput placeholder="Enter New Password" type="password" formControlName="newPassword">
                        </mat-form-field>
                    </div>
                    <div class="col-6">
                        <mat-form-field appearance="outline" class="mat-content">
                            <mat-label>Confirm Password</mat-label>
                            <input matInput placeholder="Enter Confirm Password" type="password" formControlName="confirmPassword">
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
            <mat-divider></mat-divider>
            <br>
            <mat-card-footer>
                <mat-card-actions>
                    <button mat-stroked-button color="primary" type="submit">Save</button> &nbsp;
                    <button mat-stroked-button color="warn" type="reset">Reset</button>
                </mat-card-actions>
            </mat-card-footer>
        </form>
    </mat-card>
</div>