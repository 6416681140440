<div>
    <!-- <mat-paginator [length]="totalActivityCount"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="onPageChange($event)">
    </mat-paginator> -->
    <table mat-table [dataSource]="activityData" class="demo-table">
    
      <!-- Activity Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Activity Name</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!element.editing">{{ element.name }}</span>
          <mat-form-field appearance="outline" *ngIf="element.editing">
            <input matInput [(ngModel)]="element.name" required>
          </mat-form-field>
          <!-- <input *ngIf="element.editing" type="text" [(ngModel)]="element.name" matInput class="editable-input"> -->
        </td>
      </ng-container>

      <!-- Category Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> Category Type </th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="!element.editing">{{ getCategoryName(element.categoryTypeId) }}</span>
          <mat-form-field appearance="outline" *ngIf="element.editing">
            <mat-select [(ngModel)]="element.categoryTypeId" required>
              <mat-option *ngFor="let activity of typeList" [value]="activity.id">{{ activity.type }}</mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <select *ngIf="element.editing" [(ngModel)]="element.categoryTypeId" matInput class="editable-input">
            <option *ngFor="let type of typeList" [value]="type.id">{{ type.type }}</option>
          </select> -->
        </td>
      </ng-container>
    
      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="primary" *ngIf="!element.editing" (click)="editActivity(element)">Edit</button> &nbsp;
          <button mat-raised-button color="warn" *ngIf="!element.editing" (click)="deleteActivity(element)">Delete</button> &nbsp;
          <button mat-raised-button color="primary" *ngIf="element.editing" (click)="saveActivity(element)">Save</button> &nbsp;
          <button mat-raised-button *ngIf="element.editing" (click)="cancelEdit(element)">Cancel</button>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  