<div>
  <mat-paginator [length]="totalUsersCount"
                 [pageSize]="pageSize"
                 [pageSizeOptions]="[5, 10, 25, 100]"
                 (page)="onPageChange($event)">
  </mat-paginator>
  <table mat-table [dataSource]="userData" class="demo-table">
  
    <!-- First Name Column -->
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef> First Name </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.firstName }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [(ngModel)]="element.firstName">
        </mat-form-field>
        <!-- <input *ngIf="element.editing" type="text" [(ngModel)]="element.firstName" matInput class="editable-input"> -->
      </td>
    </ng-container>
  
    <!-- Last Name Column -->
    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef> Last Name </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.lastName }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [(ngModel)]="element.lastName">
        </mat-form-field>
        <!-- <input *ngIf="element.editing" type="text" [(ngModel)]="element.lastName" matInput class="editable-input"> -->
      </td>
    </ng-container>
  
    <!-- User Name Column -->
    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef> User Name </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.userName }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [(ngModel)]="element.userName">
        </mat-form-field>
        <!-- <input *ngIf="element.editing" type="text" [(ngModel)]="element.userName" matInput class="editable-input"> -->
      </td>
    </ng-container>
  
    <!-- Contact Column -->
    <ng-container matColumnDef="contact">
      <th mat-header-cell *matHeaderCellDef> Contact </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.contact }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [(ngModel)]="element.contact">
        </mat-form-field>
        <!-- <input *ngIf="element.editing" type="text" [(ngModel)]="element.contact" matInput class="editable-input"> -->
      </td>
    </ng-container>
  
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.email }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [(ngModel)]="element.email">
        </mat-form-field>
        <!-- <input *ngIf="element.editing" type="text" [(ngModel)]="element.email" matInput class="editable-input"> -->
      </td>
    </ng-container>
  
    <!-- Department Column -->
    <ng-container matColumnDef="department">
      <th mat-header-cell *matHeaderCellDef> Department </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ getDepartmentName(element.departmentId) }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <mat-select [(ngModel)]="element.departmentId">
            <mat-option *ngFor="let department of departments" [value]="department.id">{{ department.department }}</mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <select *ngIf="element.editing" [(ngModel)]="element.departmentId" matInput class="editable-input">
          <option *ngFor="let department of departments" [value]="department.id">{{ department.department }}</option>
        </select> -->
      </td>
    </ng-container>
  
    <!-- Employee Code Column -->
    <ng-container matColumnDef="employeeCode">
      <th mat-header-cell *matHeaderCellDef> Employee Code </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.employeeCode }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [(ngModel)]="element.employeeCode">
        </mat-form-field>
        <!-- <input *ngIf="element.editing" type="text" [(ngModel)]="element.employeeCode" matInput class="editable-input"> -->
      </td>
    </ng-container>
  
    <!-- Joining Date Column -->
    <ng-container matColumnDef="joiningDate">
      <th mat-header-cell *matHeaderCellDef> Joining Date </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.joiningDate | date: 'dd-MM-yyyy' }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [matDatepicker]="joiningDatePicker" [(ngModel)]="element.joiningDate">
          <mat-datepicker-toggle matSuffix [for]="joiningDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #joiningDatePicker></mat-datepicker>
        </mat-form-field>
        <!-- <div *ngIf="element.editing">
          <input matInput [matDatepicker]="joiningDatePicker" [(ngModel)]="element.joiningDate" class="editable-input">
          <mat-datepicker #joiningDatePicker></mat-datepicker>
          <mat-datepicker-toggle matSuffix [for]="joiningDatePicker"></mat-datepicker-toggle>
        </div> -->
      </td>
    </ng-container>
  
    <!-- Birth Date Column -->
    <ng-container matColumnDef="birthDate">
      <th mat-header-cell *matHeaderCellDef> Birth Date </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="!element.editing">{{ element.birthDate | date: 'dd-MM-yyyy' }}</span>
        <mat-form-field appearance="outline" *ngIf="element.editing">
          <input matInput [matDatepicker]="birthDatePicker" [(ngModel)]="element.birthDate">
          <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #birthDatePicker></mat-datepicker>
        </mat-form-field>
        <!-- <div *ngIf="element.editing">
          <input matInput [matDatepicker]="birthDatePicker" [(ngModel)]="element.birthDate" class="editable-input">
          <mat-datepicker #birthDatePicker></mat-datepicker>
          <mat-datepicker-toggle matSuffix [for]="birthDatePicker"></mat-datepicker-toggle>
        </div> -->
      </td>
    </ng-container>
  
    <!-- Role Column -->
<ng-container matColumnDef="role">
  <th mat-header-cell *matHeaderCellDef> Role </th>
  <td mat-cell *matCellDef="let element">
    <span *ngIf="!element.editing">{{ getRoleName(element.roleId) }}</span>
    <mat-form-field appearance="outline" *ngIf="element.editing">
      <mat-select [(ngModel)]="element.roleId">
        <mat-option *ngFor="let role of roles" [value]="role.id">{{ role.role }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <select *ngIf="element.editing" [(ngModel)]="element.roleId" matInput class="editable-input">
      <option *ngFor="let role of roles" [value]="role.id">{{ role.role }}</option>
    </select> -->
  </td>
</ng-container>
  
    <!-- Employee Type Column -->
<ng-container matColumnDef="employeeType">
  <th mat-header-cell *matHeaderCellDef> Employee Type </th>
  <td mat-cell *matCellDef="let element">
    <span *ngIf="!element.editing">{{ getEmployeeTypeName(element.employeeTypeId) }}</span>
    <mat-form-field appearance="outline" *ngIf="element.editing">
      <mat-select [(ngModel)]="element.employeeTypeId">
        <mat-option *ngFor="let employeeType of employeeTypes" [value]="employeeType.id">{{ employeeType.type }}</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- <select *ngIf="element.editing" [(ngModel)]="element.employeeTypeId" matInput class="editable-input">
      <option *ngFor="let employeeType of employeeTypes" [value]="employeeType.id">{{ employeeType.type }}</option>
    </select> -->
  </td>
</ng-container>
  
    <!-- Actions Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions </th>
      <td mat-cell *matCellDef="let element">
        <button mat-raised-button color="primary" *ngIf="!element.editing" (click)="editUser(element)">Edit</button> &nbsp;
        <button mat-raised-button color="warn" *ngIf="!element.editing" (click)="deleteUser(element)">Delete</button> &nbsp;
        <button mat-raised-button color="secondary" *ngIf="!element.editing" (click)="resetUser(element)">Reset</button> &nbsp;
        <button mat-raised-button color="primary" *ngIf="element.editing" (click)="saveUser(element)">Save</button> &nbsp;
        <button mat-raised-button *ngIf="element.editing" (click)="cancelEdit(element)">Cancel</button>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
