import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../services/user.service';
import { UserDTO } from './user.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Role } from '../user/role.model'; // Ensure Role interface or type is imported
import { Department } from '../user/department.model';
import { UserListComponent } from '../user-list/user-list.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  @ViewChild(UserListComponent) userListCompo: any;
  displayedColumns: string[] = [
    'firstName',
    'lastName',
    'userName',
    'contact',
    'email',
    'department',
    'employeeCode',
    'joiningDate',
    'birthDate',
    'role',
    'employeeType'
  ];
  userData: UserDTO[] = [];
  userForm: FormGroup;
  isSubmitting: boolean = false;
  submitSuccess: boolean = false;
  submitError: string = '';
  roles: Role[] = []; // Ensure Role interface or type is used
  departments: Department[] = [];
  employeeTypes = [
    // { id: 0, employeeType: 'ADMIN' },
    { id: 1, employeeType: 'EMPLOYEE' },
    { id: 2, employeeType: 'MERIL CONSULTANT' },
    { id: 3, employeeType: 'PRIME CONSULTANT' },
  ];
  usersJsonData:any
  isAdmin: boolean = false;
  defaultDepartment: string = 'OPERATIONS';

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {
    this.userForm = this.fb.group({
      userName: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: [''],
      contact: [''],
      departmentId: [''],
      employeeCode: [''],
      joiningDate: [''],
      birthDate: [''],
      role: ['', Validators.required], // Use FormControl for role
      employeeType: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.getStorageData()
    this.getRoles(); // Fetch roles on component initialization
    this.getDepartments(); // Fetch departments on component initialization
    this.getAllUsers(); // Fetch users on component initialization
    console.log(this.userForm);
    
  }

  getStorageData(){
    let user:any = sessionStorage.getItem("userData")
    this.usersJsonData = JSON.parse(user);
    this.isAdmin = this.usersJsonData.roleId == 0;
  }

  getRoles(): void {
    this.userService.getRoles().subscribe(
      (responseObject: any) => {
        this.roles = responseObject.map((role: any) => ({ id: role.id, role: role.role }));
        console.log('Roles fetched:', this.roles); // Debugging: log fetched roles
      },
      (error: any) => {
        console.error('Error fetching roles:', error);
        this.snackBar.open(`Error fetching roles: ${error.message}`, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }

  getDepartments(): void {
    this.userService.getDepartments().subscribe(
      (responseObject: any) => {
        this.departments = responseObject.map((department: any) => ({ id: department.id, department: department.department }));
        console.log('Departments fetched:', this.departments); // Debugging: log fetched departments
        //this.userForm.get('departmentId')?.setValue(3)
      },
      (error: any) => {
        console.error('Error fetching departments:', error);
        // this.snackBar.open(`Error fetching departments: ${error.message}`, 'Close', {
        //   duration: 3000,
        //   verticalPosition: 'bottom',
        //   horizontalPosition: 'center'
        // });
      }
    );
  }

  getEmployeeTypeName(employeeTypeId: number): string | undefined {
    const employeeType = this.employeeTypes.find(et => et.id === employeeTypeId);
    return employeeType ? employeeType.employeeType : undefined;
  }

  getEmployeeTypeId(employeeTypeName: string): number | undefined {
    const employeeType = this.employeeTypes.find(et => et.employeeType === employeeTypeName);
    return employeeType ? employeeType.id : undefined;
  }

  getAllUsers(): void {
    this.userService.getAllUsers(this.usersJsonData.id, 1, 10).subscribe(
      (res: any) => {
        if (res && res.responseObject && res.responseObject.responseData) {
          if (Array.isArray(res.responseObject.responseData)) {
            this.userData = res.responseObject.responseData.map((user: UserDTO) => ({
             ...user,
              role: this.getRoleName(user.roleId),
              department: this.getDepartmentName(user.departmentId)
            }));
          } else {
            console.error("Invalid response data:", res);
          }
          // this.snackBar.open('Users fetched successfully!', 'Close', {
          //   duration: 3000,
          //   verticalPosition: 'bottom',
          //   horizontalPosition: 'center'
          // });
        } else {
          console.error("Invalid users response format:", res);
          this.snackBar.open(res.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
        }
      },
      (error: any) => {
        console.error('Error fetching users:', error);
        this.snackBar.open(error.message, 'Close', {
          duration: 3000,
          verticalPosition: 'bottom',
          horizontalPosition: 'center'
        });
      }
    );
  }
  
  getRoleName(roleId: number): string | undefined {
    const role = this.roles.find(r => r.id === roleId);
    return role ? role.role : undefined;
  }

  getDepartmentName(departmentId: number): string | undefined {
    const department = this.departments.find(d => d.id === departmentId);
    return department ? department.department : undefined;
  }

  onSave(): void {
    if (this.userForm.valid) {
      const formValue = this.userForm.value;
      const userData = {
       ...formValue,
        roleId: this.getRoleId(formValue.role),
        departmentId: this.getDepartmentId(formValue.departmentId),
        employeeTypeId: this.getEmployeeTypeId(formValue.employeeType)
      };
  
      this.userService.create(userData).subscribe(
        (res: any) => {
          this.isSubmitting = false;
          this.submitSuccess = true;
          this.userData.push(res.responseObject); // Add new user data to the array
          // this.getAllUsers(); // Refresh the table
          this.userListCompo.getAllUsers(1,10);
          this.snackBar.open(res.message, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
  
          // Reset form and clear error states
          this.clearFormData()
          console.log(this.userForm);
          
        },
        (error: any) => {
          this.isSubmitting = false;
          this.submitSuccess = false;
          this.submitError = 'Error submitting form';
          this.snackBar.open(`Error submitting form: ${error.message}`, 'Close', {
            duration: 3000,
            verticalPosition: 'bottom',
            horizontalPosition: 'center'
          });
          console.error('Error submitting form: ', error);
        }
      );
    } else {
      this.submitSuccess = false;
      this.submitError = 'Form is invalid. Please check all fields.';
      this.snackBar.open('Form is invalid. Please check all fields.', 'Close', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
  
      // Mark all fields as touched to show errors
      this.userForm.markAllAsTouched();
    }
  }
  
  getRoleId(roleName: string): number | undefined {
    const role = this.roles.find(r => r.role === roleName);
    return role ? role.id : undefined;
  }

  getDepartmentId(departmentName: string): number | undefined {
    const department = this.departments.find(d => d.department === departmentName);
    return department ? department.id : undefined;
  }

  clearFormData(){
    this.userForm.reset()
    Object.keys(this.userForm.controls).forEach(key => {
      this.userForm.get(key)?.setErrors(null);
    });
    this.userForm.markAsPristine()
    this.userForm.markAsUntouched()
    this.userForm.updateValueAndValidity()
    // this.userForm.
  }
}
