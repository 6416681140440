// userService.ts

import { Injectable } from '@angular/core';
import { Observable, catchError, map, tap, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserDTO } from '../components/user/user.model';
import { Role } from '../components/user/role.model';
import { Department } from '../components/user/department.model'; // Add department model
import { baseURL, getHeaders, VERSION } from '../constant/constant';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  create(userDTO: UserDTO): Observable<UserDTO> {
    return this.http.post<UserDTO>(`${baseURL}users/${VERSION}`, userDTO, getHeaders());
  }

  getAllUsers(userId:any, pageNo: number, pageSize: number): Observable<UserDTO[]> {
    const options = {
      headers: getHeaders().headers
    };
    return this.http.get<UserDTO[]>(`${baseURL}users/${VERSION}/list?userId=${userId}&pageNo=${pageNo}&pageSize=${pageSize}`, options)
      .pipe(
        tap(response => console.log('getAllUsers response:', response)),
        catchError(error => {
          console.error('Error fetching users:', error);
          return throwError(error);
        })
      );
  }

  getUserById(id: number): Observable<UserDTO> {
    return this.http.get<UserDTO>(`${baseURL}users/${VERSION}?id=${id}`, getHeaders());
  }

  update(userDTO: UserDTO): Observable<UserDTO> {
    console.log('Updating user:', userDTO);
    return this.http.put<UserDTO>(`${baseURL}users/${VERSION}`, userDTO, getHeaders()).pipe(
      tap(response => console.log('Update response:', response)),
      catchError(error => {
        console.error('Error updating user:', error);
        return throwError(error);
      })
    );
  }

  deleteById(id: number): Observable<UserDTO> {
    return this.http.delete<UserDTO>(`${baseURL}users/${VERSION}/delete?id=${id}`, getHeaders());
  }

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${baseURL}users/${VERSION}/roles`).pipe(
      tap((roles: Role[]) => console.log('getRoles response:', roles)),
      catchError(error => {
        console.error('Error fetching roles:', error);
        return throwError(error);
      })
    );
  }

  getDepartments(): Observable<Department[]> { // Add getDepartments method
    return this.http.get<Department[]>(`${baseURL}users/${VERSION}/departments`).pipe(
      tap((departments: Department[]) => console.log('getDepartments response:', departments)),
      catchError(error => {
        console.error('Error fetching departments:', error);
        return throwError(error);
      })
    );
  }

  resetUserPassword(id: number): Observable<UserDTO> {
    return this.http.get<UserDTO>(`${baseURL}users/${VERSION}/reset?id=${id}`, getHeaders());
  }
}