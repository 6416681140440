import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent implements OnInit{
  
  changePasswordForm?:FormGroup
  userData:any
  constructor(private route:Router,private loginAPI:LoginService,private snackBar: MatSnackBar){}
  
  ngOnInit(): void {
    this.getStorageData()
    this.createForm()
  }

  getStorageData(){
    let user:any = sessionStorage.getItem("userData")
    this.userData = JSON.parse(user)
    
  }

  createForm(){
    this.changePasswordForm = new FormGroup({
      id:new FormControl(this.userData.id),
      oldPassword:new FormControl('',[Validators.required]),
      newPassword:new FormControl('',[Validators.required]),
      confirmPassword:new FormControl('',[Validators.required])
    })
  }

  validateChangePassword(){
    let formData = (this.changePasswordForm as FormGroup)
    if(formData.value.oldPassword == null || formData.value.oldPassword == ''){
      this.snackBar.open('Enter old password', 'Close', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
      return false
    }else if(formData.value.newPassword == null || formData.value.newPassword == ''){
      this.snackBar.open('Enter new passord', 'Close', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
      return false
    }else if(formData.value.confirmPassword == null || formData.value.confirmPassword == ''){
      this.snackBar.open('Enter confirm password', 'Close', {
        duration: 3000,
        verticalPosition: 'bottom',
        horizontalPosition: 'center'
      });
      return false
    }
    return true
  }

  setChangePassword(){
    if(this.validateChangePassword()){
      this.loginAPI.changePassword(this.changePasswordForm?.value).subscribe({
        next : (res:any) => {
          console.log("res :: ",res);
          if(res.status == 200){
            this.snackBar.open("Password Change Successfully")
            this.route.navigate(['/home/dashboard'])
          }
        }
      })
    }
  }
}
